import React, {useEffect, useState, createContext} from 'react';

//CSS
import './assets/sass/ai-main.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

//Fonts
import 'remixicon/fonts/remixicon.css'
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/700.css"


import RouteIndex from './routes/RouteIndex';
import {getPostData} from "./functions/getPageData";
import Popup from "./components/popup/popup";
import AiWhatsappIcon from "./components/abstract/AiWhatsappIcon";

function App() {

    const [popups, setPopups] = useState()
    const [showPopup, setShowPopup] = useState(false)
    const [popupImage, setPopupImage] = useState(undefined)
    const [showCalendarPopup, setShowCalendarPopup] = useState(false);
    const handleCalendarPopup = () => {
        setShowCalendarPopup(!showCalendarPopup)
    }
    const UserContext = createContext();

    //Call Popups
    useEffect(()=>{
        getPostData('popups?order=asc').then((popups)=> setPopups(popups))
    }, [])

    useEffect(()=>{
        //Check If Popups
        if(popups !== undefined && popups.length > 0){
            if(popups[0] !== undefined){
                if(popups[0]['fimg_url'] !== false){
                    setPopupImage(popups[0]['fimg_url'])
                    if(window.sessionStorage.getItem('showPopup') === null){
                        setShowPopup(true)
                    }
                } else {
                    console.warn('No image set in popup featured image field')
                }
            }
        }
    }, [popups])


      return (
          <div className="app">
              {popups !== undefined && popups.length > 0 && showPopup && popupImage !== undefined &&
                <Popup setShowPopup={setShowPopup} popupImage={popupImage}/>
              }
                <RouteIndex/>

          </div>
            
      );
}

export default App;
