import React, {useEffect, useState} from 'react';
import AISearchBar from './SearchBar';
import { Modal } from 'react-bootstrap';

function AISearch() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
        <Modal  dialogClassName="ai-search-bar-modal" show={show} fullscreen={true}  size="lg" backdrop="static" onHide={handleClose}>
        <Modal.Header>
            {/* <Link to="#" onClick={handleClose} className="ai-modal-close d-flex">
            <i className="ri-close-line"></i> Close
            </Link> */}
            </Modal.Header>

            <Modal.Body style={{width: "80%", paddingTop: "40px"}}>
                <AISearchBar onClick={handleClose}  suggestions={[]}/>
            </Modal.Body>
           
        </Modal>
                <div className="ai-search-toggle">
                    <i className="ri-search-line" onClick={handleShow}></i>
                </div>
        </>

    )
}

export default AISearch;
