let content_url = process.env.REACT_APP_PRODUCTION_CONTENT;

// console.log('Environment: ', (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') );

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    content_url = process.env.REACT_APP_DEVELOPMENT_CONTENT;
    // content_url = process.env.REACT_APP_PRODUCTION_CONTENT;
} else {
    // production code
    // content_url = process.env.REACT_APP_DEVELOPMENT_CONTENT;
    content_url = process.env.REACT_APP_PRODUCTION_CONTENT;
}

// console.log('Production variable: ', content_url);

export function getPageData(url){
    const baseURL = content_url + "/wp-json/wp/v2/";
    return fetch(baseURL+url, {
        mode: "cors",
        headers:{
            // 'Content-Encoding': 'gzip',
        }})
        .then(res => res.json())
        .then((result) => {
                return result
            },
            (error) => {
                return error
            });
}

export function getPostData(url){
    const baseURL = content_url + "/wp-json/wp/v2/";
    return fetch(baseURL+url, {
        mode: "cors",
        headers:{
            // 'Content-Encoding': 'gzip',
        }
    })
        .then(res => res.json())
        .then( (result) => {
                return result
            },
            (error) => {
                return error
            });
}

export function getSearchResults(searchValue){
    const baseURL = content_url + "/wp-json/wp/v2/search";
    return fetch(baseURL+searchValue, {
        mode: "cors",
            headers:{
                // 'Content-Encoding': 'gzip',
            }
    }
    )
        .then(res => res.json())
        .then( (result) => {
                return result
            },
            (error) => {
                return error
            });
}