export function replaceEntity(array){
    return JSON.parse(JSON.stringify(array).replaceAll('%%','/')
        .replace(/&#8217;/g, "'")
        .replace(/&#8220;/g, '“')
        .replace(/&#8221;/g, '”')
        .replace(/&#8216;/g, "'")
        .replace(/&#038;/g, "&")
        .replace(/&#8211;/g, "&" )
        .replace(/&amp;/g, "&")

    )
}