import React, {useState} from 'react';
import {motion} from "framer-motion";

//Frame Link Variant
const linkVariant = {
    initial: { y: 0, ease: "easeOut", duration: 0.2, type: "tween" },
    hover: index => ({
        y: -24,
        transition: {
            duration: 0.2,
            type: "tween",
            ease: "easeIn",
            delay: index * 0.01,
        }
    })
}

function linkAnimation(text){
    //Split letters into an array
    let letterArray = text.split('');
    //Replace white spaces with unicode space
    letterArray.map((letter, index)=>{
        if(letterArray[index] === " "){
            letterArray[index] = "\u00A0"
        }
    })

    //return Animated Text
    return (
        <div className={'ai-link-animation-container'}>
            <div className={'ai-link-top-animation'}>
                {
                    letterArray.map((letter, index) => (
                        <React.Fragment key={index}>
                            <motion.span style={{display: 'inline-block'}}
                                         variants={linkVariant}
                                         custom={index}
                            >{letter}</motion.span>
                        </React.Fragment>
                    ))
                }
            </div>
            <div className={'ai-link-bottom-animation'}>
                {
                    letterArray.map((letter, index) => (
                        <React.Fragment key={index}>
                            <motion.span  style={{display: 'inline-block'}}
                                         variants={linkVariant}
                                         custom={index}
                            >{letter}</motion.span>
                        </React.Fragment>
                    ))
                }
            </div>
        </div>
    )
}


function AILink(props) {
    //State
    const [showDropdownMenu, setShowDropdownMenu] = useState(false)

    //Functions
    function handleDropdownToggle(){
        setShowDropdownMenu(!showDropdownMenu)
    }

    return(
        <span className={`ai-link-container ${showDropdownMenu ? 'active-dropdown' : ''}`}>
        <motion.a className={`ai-link ${props.className ? props.className : ''}` }
                  href={props.url}
                  whileHover={'hover'}
                  target={props.target ? props.target : null}
        >
            { !props.linkAnimation ? linkAnimation(props.text) : 
            <div className='ai-link-animation-container'>
                {props.text}
            </div>
            } 
            {props.type === 'ai-dropdown-link' ? <i className={'ri-arrow-down-s-line ai-desktop'}></i> : undefined}
        </motion.a>
            <span className={'ai-mobile-dropdown-toggle'} onClick={handleDropdownToggle}>
                <i className={'ri-arrow-down-s-line ai-mobile ai-mobile-dropdown'}></i>
            </span>
        </span>
    )
}


export default  AILink;