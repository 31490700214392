import React, { useState, useLayoutEffect } from 'react';
import {
    BrowserRouter as Router,
} from "react-router-dom";

const AIRouter = ({ history, ...props }) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router history={history}
            {...props}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        />
    );
};

export default AIRouter;